/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@include mat.core();
// html {
//     @include mat.theme((
//       color: (
//         primary: mat.$violet-palette,
//         tertiary: mat.$orange-palette,
//         theme-type: light,
//       ),
//       typography: Roboto,
//       density: 0
//     ));
// }
@import "@ng-select/ng-select/themes/default.theme.css";
@import 'bootstrap/dist/css/bootstrap.min.css';

a:hover{text-decoration: none}


body .autocomplete-container .input-container input{
	border-radius: 50px 0 0 50px;
    border: 1px solid #ccc;
    background: #fff;
    color: #989898;
    display:flex;
    height:45px;
    font-size:16px;
}
.search-bx div.ng-autocomplete{
	display:flex;
}
.autocomplete-container{
	box-shadow:none !important;
}
.ng-autocomplete .ser-btn{
	height:45px;
}
.autocomplete-container .suggestions-container ul li a{
    padding: 5px 15px !important;
}

// .hideobj{
//     display:none;
// }